.timeline-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 5rem 0;
    width: 100%;
}

.timeline-container-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
}
.timeline-container-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.timeline-container:after {
    background-color: red;
    content: "";
    width: 4px;
    height: 100%;
    position: absolute;
    left: calc(50% - 2px)
}

.timeline-item h2 {
    margin-top: 1rem;
}

.timeline-container-left .timeline-item-content {
    align-items: flex-end;
}
.timeline-container-right .timeline-item-content {
    align-items: flex-start;
}
.timeline-container-right .timeline-item {
    padding-left: 2rem
}
.timeline-container-left .timeline-item {
    padding-right: 2rem
}

.timeline-item-content {
    background-color: white;
    padding: 0.5rem 1rem;
    width: 100%;
    box-shadow: 0 0 5px rgba(0,0,0,.3);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.timeline-item-content:after {
    position: absolute;
    content: "";
    height: 1rem;
    width: 1rem;
    top: calc(50% - 0.5rem);
    transform: rotate(45deg);
    background-color: white;
    box-shadow: -1px 1px 1px rgba(0,0,0,.2);
}

.timeline-container-left .timeline-item-content:after {
    left: auto;
    right: -0.5rem;
    box-shadow: 1px -1px 1px rgba(0,0,0,.2);
}

.timeline-container-right .timeline-item-content:after {
    right: auto;
    left: -0.5rem;
}

.timeline-item {
    min-width: 50%;
    max-width: 70%;
}

.timeline-item p {
    max-width: 70%;
    word-wrap: break-word;
}

.blank-item {
    height: 12rem;
}

.timeline-container-right .timeline-label {
    left: auto;
    right: 0.5rem;
}
.timeline-container-left .timeline-label {
    right: auto;
    left: 0.5rem;
}  
.timeline-label {
    padding: 0 1rem;
    color: white;
    position: absolute;
    max-width: 70%;
    word-break: break-all;
}

.timeline-circle {
    height: 2rem;
    width: 2rem;
    background-color: white;
    border-radius: 50%;
    border: 5px solid #ff4b3e;
    position: absolute;
    top: calc(50% - 1rem);
    z-index: 999;
}
.timeline-container-left .timeline-circle {
    left: auto;
    right: -3rem;
}
.timeline-container-right .timeline-circle {
    right: auto;
    left: -3rem;
}

.timeline-container-left .timeline-item-content-try {
    animation-name: pop-left;
    animation-duration: .5s;
    animation-timing-function: ease-out
}

.timeline-container-right .timeline-item-content-try {
    animation-name: pop-right;
    animation-duration: .5s;
    animation-timing-function: ease-out
}

.timeline-item-content-try .timeline-circle {
    animation-name: fade-in;
    animation-duration: 1s;
    animation-timing-function: ease-out
}

.timeline-item-content-hidden {
    opacity: 0;
}

.timeline-container-right .timeline-item-content:hover {
    margin-left: 1rem;
}
.timeline-container-right .timeline-item-content:hover .timeline-circle {
    left:-4rem;
}
.timeline-container-left .timeline-item-content:hover {
    margin-left: -1rem;
}
.timeline-container-left .timeline-item-content:hover .timeline-circle {
    right:-4rem;
}
.timeline-circle {
    transition: all 1s;
}
.timeline-item-content {
    transition: margin 1s;
}
@media all and (max-width: 1100px) {
    .timeline-item {
        max-width: 80%;
    }
    .timeline-item p {
        max-width: 90%;
    }
}
@media all and (max-width: 875px) {
    .timeline-label {
        position: relative;
        right: 0;
        left: 0;
        max-width: 100%;
    }
    .timeline-container-left .timeline-label {
        margin-right: auto;
        margin-left: -1rem;
    }
    .timeline-container-right .timeline-label {
        margin-left: auto;
        margin-right: -1rem;
    }
    .timeline-item p {
        max-width: 100%;
    }
  }

  @media all and (max-width: 725px) {
    .timeline-item {
        max-width: 90%;
    }
    .timeline-item-content:hover {
        margin-left: 0 !important;
    }
    .timeline-container-left .timeline-item-content:hover .timeline-circle {
        right: -3rem !important;
    }
    .timeline-container-right .timeline-item-content:hover .timeline-circle {
        left: -3rem !important;
    }
}

    @media all and (max-width: 550px) {
        .timeline-item p{
            font-size: 0.85rem;
        }
        .timeline-label{
            font-size: 0.8rem;
        }
        .timeline-item h2 {
            font-size: 1.2rem;
        }
        .timeline-item time {
            font-size: 0.8rem;
        }
        .timeline-circle {
            height: 1.5rem;
            width: 1.5rem;
            top: calc(50% - 0.75rem);
        }
        .timeline-container-left .timeline-circle {
            left: auto;
            right: -2.75rem;
        }
        .timeline-container-right .timeline-circle {
            right: auto;
            left: -2.75rem;
        }
        .timeline-container-left .timeline-item-content:hover .timeline-circle {
            right: -2.75rem !important;
        }
        .timeline-container-right .timeline-item-content:hover .timeline-circle {
            left: -2.75rem !important;
        }
    }
    @media all and (max-width: 450px) {
        .timeline-item {
            max-width: 95%;
        }
        .timeline-item p{
            font-size: 0.6rem;
        }
        .timeline-label{
            font-size: 0.6rem;
        }
        .timeline-item h2 {
            font-size: 1rem;
        }
        .timeline-container:after {
            width: 3px;
            left: calc(50% - 1.5px);
        }
        .timeline-circle {
            height: 1.25rem;
            width: 1.25rem;
            border-width: 3px;
            top: calc(50% - 0.625rem);
        }
        .timeline-container-left .timeline-circle {
            left: auto;
            right: -2.625rem;
        }
        .timeline-container-right .timeline-circle {
            right: auto;
            left: -2.625rem;
        }
        .timeline-container-left .timeline-item-content:hover .timeline-circle {
            right: -2.625rem !important;
        }
        .timeline-container-right .timeline-item-content:hover .timeline-circle {
            left: -2.625rem !important;
        }
        .timeline-item h2 {
            margin: 0;
        }
    }