#construction-title-view {
    background: linear-gradient(0deg,rgba(0,0,0,.3),rgba(0,0,0,.3)),url('https://images.pexels.com/photos/15641049/pexels-photo-15641049/free-photo-of-low-angle-shot-of-a-construction-site.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.construction-nav {
    align-self: start;
}
#construction-title {
    align-self: center;
    position: absolute;

}

@media all and (max-width: 450px) {
    #construction-title h1 {
        font-size: 3.5rem;
    }
    #construction-title {
        height: 13.5rem;
    }
}
@media all and (max-width: 375px) {
    #construction-title h1 {
        font-size: 2.75rem;
    }
    #construction-title {
        height: 12rem;
    }
}