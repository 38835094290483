.top-view {
  width: 100%;
  height: 100vh;
  position: relative;
  /* background: linear-gradient(0deg,rgba(0,0,0,.3),rgba(0,0,0,.3)),url('https://cdn.pixabay.com/photo/2012/10/20/02/06/rocky-62127_1280.jpg'); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  justify-items: center;
  align-items: end
}
.section-sub-heading {
    font-family: 'Varela Round';
    font-size: 1.25rem;
}
.section-sub-heading-try {
    animation-name: fade-in;
    animation-duration: 0.75s;
    animation-timing-function: ease-in-out
}
.section-sub-heading-hidden {
  opacity: 0;;
}
.name-wrapper {
  min-width: 70%;
  height: 15rem;
  border: 10px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,.05);
  backdrop-filter: blur(2.5px);
  align-self: center;
  text-align: center;
  max-width: 90%;
  padding: 0 2rem;
}
.name-wrapper-try {
  animation-name: drift-up;
  animation-duration: 1s;
  animation-timing-function: ease-in-out
}
.name-wrapper-hidden {
  opacity: 0;
}
.name-wrapper h1 {
  color: #fff;
  font-size: 5rem
}
.main-view {
  height: 80%;
  width: 100%;
  position: relative
}
.body {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  height: 100%
}
.down-arrow {
  position: relative;
  height: 2.5rem;
  width: auto;
  bottom: 2rem
}
.down-arrow:hover {
  animation-name: wiggle;
  animation-duration: 250ms;
  animation-timing-function: ease-in-out
}
.section-view {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center
}
.section-view h2 {
  position: relative;
}
.section-heading {
  font-size: 3rem;
  margin-bottom: 3rem;
  border-bottom: 3px solid #ff4b3e;
  font-family: 'IBM Plex Sans';
  text-align: center;
  margin-top: 5rem;
}
.about-wrapper-top {
  margin: 0 3rem;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  height: 50%
}
.about-content {
  position: relative;
  font-size: 1.25rem
}
.portrait-img {
  height: 520px;
  border-radius: 10%
}
.portrait-img-try {
  animation-name: pop-right;
  animation-duration: .5s;
  animation-timing-function: ease-in-out
}
.portrait-img-hidden {
  opacity: 0;
}
.about-content-wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 3rem
}
.about-slide {
  object-fit: cover;
  height: 400px;
  width: 550px;
  margin: 0;
  border-radius: 10%
}
.about-slide-title {
  font-size: 2rem;
  font-family: 'Varela Round';
  font-weight: 700
}
.about-slide-caption {
  font-family: 'Varela Round';
  font-weight: 700
}
.about-slide-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  /* height: 50%; */
  position: relative;
  top: -5rem;
  padding-left: 5rem
}
.about-slide-wrapper-try {
  animation-name: pop-left;
  animation-duration: .5s;
  animation-timing-function: ease-out
}
.about-slide-wrapper-hidden {
  opacity: 0;
}
.about-slide-wrapper * {
  max-height: 100%
}
.hardskills-view {
  background-color: #f5f8fd;
  justify-content: space-evenly;
}
.hardskills-wrapper {
  display: grid;
  align-items: center;
  justify-items: center;
  width: 100%;
}
.images-container {
  display: flex;
  flex-direction: row;
  align-self: end;
  margin-bottom: 2rem;
  justify-content: space-around;
  width: 80%;
}
.hardskill-image {
  height: 6rem
}
.skill-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.skill-wrapper-try img {
    animation-name: pop-up;
    animation-duration: .5s;
    animation-timing-function: ease-out;
    z-index: 999;
}

.skill-wrapper-try p {
    animation-name: fade-in;
    animation-duration: .5s;
    animation-timing-function: ease-out
}

.skill-wrapper p {
    font-family: 'Varela Round';
}

.skill-wrapper-try .skill-bar-inner {
    animation-name: bar-rise;
    animation-duration: .75s;
    animation-timing-function: ease-out
}
.skill-wrapper-hidden {
  opacity: 0;;
}

.softskills-view {
    justify-content: space-evenly;
}

.softskills-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.softskills-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 50%;
    padding: 0 5%;
}

.softskills-left {
    align-items: flex-end;
    text-align: right;
}
.softskills-right {
    align-items: flex-start;
}

.softskills-right .softskill-try {
    animation-name: pop-right;
    animation-duration: .5s;
    animation-timing-function: ease-out
}
.softskills-left .softskill-try {
    animation-name: pop-left;
    animation-duration: .5s;
    animation-timing-function: ease-out
}

.softskill-hidden {
  opacity: 0;;
}

.softskill {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.softskill-check {
    height: 1.5rem;
    padding: 0 2rem;
}

#softskill-view-heading {
    top: 0;
}

#experience-view {
    background-color: #f5f8fd;
    height: fit-content;
}

.experience-wrapper {
  width: 100%;
}
#homepage-social-links {
  align-self: baseline;
}
@media all and (min-width: 2000px) {
  .about-content {
    font-size: 1.5rem;
  }
}
@media all and (max-width: 1245px) {
  .about-slide-wrapper {
    top:-3rem;
  }
  .about-slide {
    height: 350px;
    width: 525px;
  }
  .portrait-img {
    height: 450px;
    width: auto;
  }
  .about-content {
    font-size: 1.125rem;
  }
}
@media all and (max-width: 1000px) {
  .about-wrapper-top {
    flex-direction: column-reverse;
  }
  .about-content-wrapper {
    margin: 0;
  }
  .portrait-img {
    border-radius: 50%;
    height: 300px;
    width: 300px;
    object-fit: cover;
    margin: 1rem 0;
    position: relative;
  }
  .section-heading {
    margin-bottom: 1rem;
  }
  .about-slide-wrapper {
    top: 0;
    padding: 0;
    margin: 2rem 0;
    width: fit-content;
  }
}
@media all and (max-width: 900px) {
  .hardskill-image {
    height: 4rem;
  }
  .images-container {
    width: 95%;
  }
}
@media all and (max-width: 700px) {
  .name-wrapper h1 {
    font-size: 4rem;
  }
}
@media all and (max-width: 600px) {
  .hardskill-image {
    height: 2.5rem;
  }
  .about-slide {
    height: 300px;
    width: 100vw;
    padding: 0 2vw;
  }
}
@media all and (max-width: 500px) {
  .softskills-wrapper {
    padding: 0 1rem;
  }
  .softskills-container {
    padding: 0 0;
  }
}
@media all and (max-width: 450px) {
  .softskill p {
    font-size: 0.825rem;
  }
  .softskill-check {
    padding: 0 1rem;
  }
}

.home-page .social-icon:hover {
  fill: #ff4b3e;
}
.home-page #resume-link:hover {
  fill: #ff4b3e;
}