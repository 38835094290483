#contact-title-view {
    background-repeat: no-repeat;
    background-size: cover;
}
#contact-title {
    align-self: auto;
    min-width: 60%;
}
#contact-title h1 {
    font-size: 6rem;
}
#contact-view {
    background-color: #292929;
}
#contact-heading {
    color: white;
    border-bottom: 3px solid #F7CB15;
}
.contact-page .social-icon:hover {
    fill: #F7CB15
}
.contact-page #resume-link:hover {
    background-color: #F7CB15
}

.contact-input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 70%;
    align-items: center;
}
.contact-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 0;
}
.contact-input {
    width: 100%;
    height: 3rem;
    background-color: #313131;
    padding: 0 0.5rem;
    border: none;
    box-shadow: 0 0 5px rgba(0,0,0,.3);
    color: white;
    transition: box-shadow 0.5s;
    font-size: 1.125rem;
    font-family: 'Varela Round'
}
.contact-input::placeholder {
    color:#F7CB15;
}
.contact-input:focus-visible {
    box-shadow: 0 0 7.5px #f7ca1578;
    animation-name: shadow-pulse;
    animation-duration: 4s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
}
#contact-body-input {
    min-height: 12rem;
    resize: vertical;
    height: fit-content;
    resize: none;
}
.contact-submit-button {
    box-shadow: 0 0 5px rgba(0,0,0,.3);
    background: #F7CB15;
    color: #252525;
    transition: background 0.5s, color 0.5s, box-shadow 0.5s;
}
#contact-submit-button-disabled {
    box-shadow: 0 0 7.5px #f7ca1578;
    background: #252525;
    color: #F7CB15;
    transition: background 0.5s, color 0.5s, box-shadow 0.5s;
    animation-name: shadow-pulse;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
}
.contact-submit-button:hover {
    background: #252525;
    box-shadow: 0 0 7.5px #f7ca1578;
    color: #F7CB15;
    animation-name: shadow-pulse;
    animation-duration: 3s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
}
.contact-submit-button:active {
    box-shadow: 0 0 17.5px #f7ca1578;
    animation: none;
}
@keyframes shadow-pulse{
    0% {box-shadow: 0 0 7.5px #f7ca1578;}
    50% {box-shadow: 0 0 17.5px #f7ca1578;}
    0% {box-shadow: 0 0 7.5px #f7ca1578;}
}

.contact-wrapper * {
    color: white;
}

.contact-social-heading {
    font-family: 'IBM Plex Sans';
    text-align: center;
    color: white;
    font-weight: 800;
}

.contact-message-heading {
    font-family: 'IBM Plex Sans';
    text-align: center;
}
.contact-social-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.contact-email {
    font-family: 'IBM Plex Sans';
    color: white;
    text-align: center;
    font-weight: 400;
    margin-top: 2rem;
}
.contact-loading {
    color: white;
}
.contact-loading-text {
    color:#505050;
    font-family: 'Varela Round';
}

@media all and (max-width: 450px) {
    #contact-title h1 {
        font-size: 5rem;
    }
    #contact-title {
        height: 13.5rem
    }
}
@media all and (max-width: 350px) {
    #contact-title h1 {
        font-size: 4rem;
    }
    #contact-title {
        height: 12rem;
    }
}